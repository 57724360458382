import {State} from "@store/state";
import {createSelector} from "@ngrx/store";

const getUserInfos = (state: State) => state.userInfos;

export const getUserAuthorizations = createSelector(
  getUserInfos,
  (state) => state.userAuthorizations
);

export const getIsUserAuthorizedToPushNotif = createSelector(
  getUserAuthorizations,
  (state) => state.isUserAuthorizedToPushNotif
);

